/*
* Licensed to the Apache Software Foundation (ASF) under one
* or more contributor license agreements.  See the NOTICE file
* distributed with this work for additional information
* regarding copyright ownership.  The ASF licenses this file
* to you under the Apache License, Version 2.0 (the
* "License"); you may not use this file except in compliance
* with the License.  You may obtain a copy of the License at
*
*   http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing,
* software distributed under the License is distributed on an
* "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
* KIND, either express or implied.  See the License for the
* specific language governing permissions and limitations
* under the License.
*/


export default function (ecModel) {
    ecModel.eachSeriesByType('radar', function (seriesModel) {
        var data = seriesModel.getData();
        var points = [];
        var coordSys = seriesModel.coordinateSystem;
        if (!coordSys) {
            return;
        }

        function pointsConverter(val, idx) {
            points[idx] = points[idx] || [];
            points[idx][i] = coordSys.dataToPoint(val, i);
        }
        var axes = coordSys.getIndicatorAxes();
        for (var i = 0; i < axes.length; i++) {
            data.each(data.mapDimension(axes[i].dim), pointsConverter);
        }

        data.each(function (idx) {
            // Close polygon
            points[idx][0] && points[idx].push(points[idx][0].slice());
            data.setItemLayout(idx, points[idx]);
        });
    });
}